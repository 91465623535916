import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Select from "../Select";
import {
  calculateProfitMargin,
  calculateBreakevenSalePrice,
  calculateEstimatedAmazonPayout,
  calculateROI,
  calculateMaximumCost,
  miscFeeCalc,
} from "../../utils/ProfitCalculator";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Tooltip from "../SettingComponents/Tooltip";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";

const ProfitCalculator = ({ data }) => {
  const initialPrices = data?.prices;
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(true);
  const [quantity, setQuantity] = useState(1);

  const prices = useSelector((state) => state.globalStates.prices);
  const selectedFulfillment = useSelector(
    (state) => state.globalStates.selectedFulfillment
  );
  const fullfillmentFees = useSelector(
    (state) => state.globalStates.fullfillmentFees
  );
  const totalFees = useSelector((state) => state.globalStates.total_fees);
  const settings = useSelector((state) => state.globalStates.settings);

  const [storageMonths, setStorageMonths] = useState(1);
  const [storageFeesPerMonth, setStorageFeesPerMonth] = useState(0);
  const [profit, setProfit] = useState(0);
  const [profitMargin, setProfitMargin] = useState(0);
  const [breakevenSalePrice, setBreakevenSalePrice] = useState(0);
  const [estimatedAmazonPayout, setEstimatedAmazonPayout] = useState(0);
  const [roi, setROI] = useState(0);
  const [maximumCost, setMaximumCost] = useState(0);
  const [referralFeePercentage, setReferralFeePercentage] = useState(0);
  const [referalFee, setReferalFee] = useState(0);
  const [miscFee, setMiscFee] = useState(0);

  const calculateTotalFees = () => {
    let totalFees = 0;

    const safeNumber = (value) => {
      const num = Number(value);
      return isNaN(num) ? 0 : num;
    };

    let storageFees = safeNumber(
      (storageFeesPerMonth * storageMonths).toFixed(2)
    );

    const referalFee = Number(
      (
        (safeNumber(prices.sale_price) * safeNumber(referralFeePercentage)) /
        100
      ).toFixed(2)
    );
    totalFees += safeNumber(referalFee);
    // totalFees += selectedFulfillment === 'FBA' ? safeNumber(data?.total_fees?.fba_breakdown?.FBAFees) : safeNumber(data?.total_fees?.fbm_breakdown?.FBMFees);
    totalFees += selectedFulfillment === "FBA" ? storageFees : 0;
    totalFees +=
      selectedFulfillment === "FBA"
        ? safeNumber(data?.total_fees?.fba_breakdown?.prep_fee)
        : 0;
    totalFees += safeNumber(data?.total_fees?.fba_breakdown?.closingFees);
    totalFees +=
      selectedFulfillment === "FBA"
        ? safeNumber(data?.total_fees?.fba_breakdown?.inbound_shipping)
        : 0;
    totalFees += Number(data?.total_fees?.fba_breakdown?.misc_fee);
    totalFees += Number(miscFee);
    totalFees +=
      selectedFulfillment === "FBA"
        ? safeNumber(fullfillmentFees.FBA)
        : safeNumber(fullfillmentFees.FBM);
    totalFees = Number(totalFees.toFixed(2));

    dispatch({
      type: "SET_TOTAL_FEES",
      payload: totalFees,
    });

    return totalFees;
  };

  useEffect(() => {
    dispatch({
      type: "SET_PRICES",
      payload: {
        cost_price:
          initialPrices?.cost_price && Number(initialPrices?.cost_price) >= 0
            ? initialPrices?.cost_price
            : 0,
        sale_price: initialPrices?.sale_price || 0,
      },
    });

    let storageDuration = data?.storageDuration;
    let storageFee = data?.total_fees?.fba_breakdown?.storageFees;

    storageDuration = Number(storageDuration);
    storageFee = Number(storageFee);

    if (storageDuration && storageFee) {
      let storageFeePM = storageFee / storageDuration;
      storageFeePM = Number(storageFeePM.toFixed(2));
      setStorageFeesPerMonth(storageFeePM);
    }

    setStorageMonths(storageDuration);

    const fbaFees = data?.total_fees?.fba_breakdown?.FBAFees;
    const fbmFees = data?.total_fees?.fbm_breakdown?.FBMFees;

    dispatch({
      type: "SET_FULFILLMENT_FEES",
      payload: {
        FBA: fbaFees,
        FBM: fbmFees,
      },
    });

    const referalFeePercent =
      data?.total_fees?.fba_breakdown?.referralFeePercent;
    const referalFee = (
      (parseFloat(prices.sale_price) * parseFloat(referalFeePercent)) /
      100
    ).toFixed(2);

    const salePrice = parseFloat(prices.sale_price);
    const costPrice = parseFloat(prices.cost_price);

    const amazonFees = totalFees;

    let profitData = calculateProfitMargin(salePrice, costPrice, amazonFees);
    setProfit(profitData[0]);
    setProfitMargin(profitData[1]);
    setBreakevenSalePrice(
      calculateBreakevenSalePrice(
        costPrice,
        salePrice,
        amazonFees,
        data?.total_fees?.fba_breakdown?.referralFeePercent
      )
    );
    setEstimatedAmazonPayout(
      calculateEstimatedAmazonPayout(salePrice, amazonFees)
    );
    setROI(calculateROI(salePrice, costPrice, amazonFees));

    setMaximumCost(
      calculateMaximumCost(
        salePrice,
        costPrice,
        amazonFees,
        Number(settings?.buyingCriteria?.minProfit) || 0,
        profitData[0],
        settings
      ),
      settings
    );
    setReferralFeePercentage(referalFeePercent);
    setReferalFee(referalFee);
    setMiscFee(
      miscFeeCalc(
        costPrice,
        data?.total_fees?.fba_breakdown?.misc_fee_percentage
      )
    );
  }, [data]);

  useEffect(() => {
    const fee = calculateTotalFees();

    dispatch({
      type: "SET_TOTAL_FEES",
      payload: fee,
    });
  }, [
    selectedFulfillment,
    storageMonths,
    fullfillmentFees,
    miscFee,
    referalFee,
  ]);

  useEffect(() => {
    let storageDuration = data?.storageDuration;
    let storageFee = data?.total_fees?.fba_breakdown?.storageFees;

    storageDuration = Number(storageDuration);
    storageFee = Number(storageFee);

    const fbaFees = data?.total_fees?.fba_breakdown?.FBAFees;
    const fbmFees = data?.total_fees?.fbm_breakdown?.FBMFees;

    // Call functions to update calculated values
    const amazonFees = totalFees;
    const salePrice = parseFloat(prices.sale_price);
    const costPrice = parseFloat(prices.cost_price);

    let profitData = calculateProfitMargin(salePrice, costPrice, amazonFees);
    setProfit(profitData[0]);
    setProfitMargin(profitData[1]);
    setBreakevenSalePrice(
      calculateBreakevenSalePrice(
        costPrice,
        salePrice,
        amazonFees,
        data?.total_fees?.fba_breakdown?.referralFeePercent
      )
    );
    setEstimatedAmazonPayout(
      calculateEstimatedAmazonPayout(salePrice, amazonFees)
    );
    const roiValue = calculateROI(salePrice, costPrice, amazonFees);
    setROI(calculateROI(salePrice, costPrice, amazonFees));
    setMaximumCost(
      calculateMaximumCost(
        salePrice,
        costPrice,
        amazonFees,
        Number(settings?.buyingCriteria?.minProfit) || 0,
        profitData[0],
        settings
      )
    );
    setMiscFee(
      miscFeeCalc(
        costPrice,
        data?.total_fees?.fba_breakdown?.misc_fee_percentage
      )
    );
    const newReferralFee = (
      (parseFloat(prices.sale_price) *
        parseFloat(data?.total_fees?.fba_breakdown?.referralFeePercent)) /
      100
    ).toFixed(2);
    setReferalFee(newReferralFee);
  }, [data, prices.sale_price, prices.cost_price, totalFees]);

  const dropdownOptions = ["FBA", "FBM"];
  const handleDropdownChange = (event) => {
    dispatch({
      type: "SET_SELECTED_FULFILLMENT",
      payload: event,
    });
  };

  const formatValue = (value) => {
    if (isNaN(value)) {
      return "$0";
    }

    if (value < 0) {
      return `-$${Math.abs(value)}`;
    }

    return `$${value}`;
  };

  const handleCostChange = (e, index) => {
    let { value } = e.target;
    value = value.replace("$", "");
    value = e.target.value.replace(/[^0-9.]/g, "");
    if (value.length > 2) {
      const dollars = value.slice(0, -2);
      const cents = value.slice(-2);
      value = `${dollars}.${cents}`;
    }

    if (index === 0) {
      dispatch({
        type: "SET_PRICES",
        payload: {
          ...prices,
          cost_price: value && Number(value) >= 0 ? value : 0,
        },
      });
    } else {
      dispatch({
        type: "SET_PRICES",
        payload: {
          ...prices,
          sale_price: value,
        },
      });
    }
  };

  const properties1 = [
    {
      title: "Cost Price",
      value: prices.cost_price,
      property: "cost_price",
    },
    {
      title: "Sale Price",
      value: prices.sale_price,
      property: "sale_price",
    },
    {
      title: "Fullfillment",
      value: selectedFulfillment,
      property: "fullfillment",
    },
  ];

  const properties2 = [
    {
      title: "Profit",
      value: profit,
      label: `$` + profit,
    },
    {
      title: "ROI",
      label: roi + "%",
      value: roi,
    },
    {
      title: "Maximum Cost",
      value: maximumCost,
      label: formatValue(maximumCost),
    },
  ];

  const properties3 = [
    {
      title: "Total Fees",
      value: formatValue(totalFees),
    },
    {
      title: "Referral Fee",
      value: formatValue(referalFee),
    },
    {
      title: "Fulfillment (" + selectedFulfillment + ")",
      value: formatValue(fullfillmentFees[selectedFulfillment]),
    },
    {
      title: "Storage Fees",
      value: formatValue((storageFeesPerMonth * storageMonths).toFixed(2)),
      strike: selectedFulfillment === "FBM" ? true : false,
    },
    {
      title: "Prep Fees",
      value: formatValue(data?.total_fees?.fba_breakdown?.prep_fee),
      strike: selectedFulfillment === "FBM" ? true : false,
    },
    {
      title: "Closing Fees",
      value: formatValue(data?.total_fees?.fba_breakdown?.closingFees),
    },
    {
      title: "Inbound Shipping",
      value: formatValue(data?.total_fees?.fba_breakdown?.inbound_shipping),
      strike: selectedFulfillment === "FBM" ? true : false,
      // tooltipText: 'Estimated Maximum Inbound Placement Service fee for Amazon Optimized Splits'
    },
    {
      title: "Misc Fees",
      value: "$" + data?.total_fees?.fba_breakdown?.misc_fee,
    },
    {
      title: "Misc Fees (% of cost)",
      value: "$" + miscFee,
    },
  ];

  const properties4 = [
    {
      title: "Profit Margin",
      value: profitMargin,
      highlighted: true,
    },
    {
      title: "Breakeven Sale Price",
      value: formatValue(breakevenSalePrice),
      highlighted: true,
    },
    {
      title: "Estimated Amz. Payout",
      value: formatValue(estimatedAmazonPayout),
      highlighted: true,
    },
  ];

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{ fontSize: "16px" }}
          >
            Profit Calculator
          </div>
          <div>{isOpen ? <FaAngleUp /> : <FaAngleDown />}</div>
        </div>
        {isOpen && (
          <>
            {properties1.map((property, index) => (
              <div
                key={index}
                className="flex items-center justify-between bg-secondary p-4 rounded-lg align-center mt-2"
                style={{ fontSize: "13px" }}
              >
                <div className="w-3/4" style={{ textAlign: "left" }}>
                  {property.title}
                </div>
                <div className="w-2/5">
                  {property.title === "Fullfillment" ? (
                    <Select
                      inputValue={selectedFulfillment}
                      handleDropdownChange={handleDropdownChange}
                      dropdownOptions={dropdownOptions}
                    />
                  ) : (
                    <input
                      type="text"
                      value={property.value}
                      onChange={(e) => {
                        const rawValue = e.target.value.replace(/[^0-9]/g, "");
                        e.target.value = `$${rawValue}`;
                        handleCostChange(e, index);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === ".") {
                          e.preventDefault();
                        }
                      }}
                      className={`text-center text-white border border-primary-light rounded-xl ${
                        property.title === "Fullfillment"
                          ? "bg-secondary"
                          : "bg-primary-dark"
                      }`}
                      style={{ borderRadius: "12px" }}
                    />
                  )}
                </div>
              </div>
            ))}
            {/* SHOW INPUT FOR ADDING FBM FEE */}
            {selectedFulfillment === "FBM" && (
              <div
                className="flex justify-between bg-secondary p-4 rounded-lg align-center mt-2"
                style={{ fontSize: "13px" }}
              >
                <div className="w-3/4" style={{ textAlign: "left" }}>
                  FBM Fee
                </div>
                <div className="w-2/5">
                  <input
                    type="text"
                    value={fullfillmentFees.FBM}
                    onChange={(e) => {
                      dispatch({
                        type: "SET_FULFILLMENT_FEES",
                        payload: {
                          ...fullfillmentFees,
                          FBM: e.target.value,
                        },
                      });
                    }}
                    className={`text-center text-white border border-primary-light rounded-xl bg-primary-dark`}
                    style={{ borderRadius: "12px" }}
                  />
                </div>
              </div>
            )}
            <div
              className="flex justify-between bg-secondary p-4 rounded-lg align-center mt-2"
              style={{ fontSize: "13px" }}
            >
              <div className="w-3/4" style={{ textAlign: "left" }}>
                Storage Months
              </div>
              <div className="w-1/4 flex items-center">
                <input
                  type="range"
                  min="0"
                  max="12"
                  value={storageMonths}
                  onChange={(e) => {
                    setStorageMonths(e.target.value);
                  }}
                  className="slider w-full"
                />
                <span className="ml-2">{storageMonths}</span>
              </div>
            </div>

            <div className="bg-secondary p-4 rounded-xl mt-2">
              {properties2.map((property, index) => (
                <div
                  key={index}
                  className={`flex justify-between`}
                  style={{ fontSize: "13px" }}
                >
                  <div>{property.title}</div>
                  <div
                    className={`flex  items-center ${
                      Number(property?.value) < 0
                        ? "bg-[#E74B4B] p-1 rounded-md mb-2"
                        : "bg-primary-light text-primary p-1 rounded-md mb-2 font-semibold"
                    }`}
                  >
                    {property.label}
                    {Number(property?.value) < 0 ? (
                      <FaArrowTrendDown className="ml-1" />
                    ) : (
                      <FaArrowTrendUp className="ml-1" />
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="bg-secondary p-4 rounded-xl mt-2">
              {properties3.map((property, index) => (
                <div
                  key={index}
                  className="flex justify-between"
                  style={{ fontSize: "13px" }}
                >
                  {property.highlighted ? (
                    <>
                      <div className="text-primary-light font-bold mt-1">
                        {property.title}
                        {property?.tooltipText && (
                          <Tooltip text={property.tooltipText} />
                        )}
                      </div>
                      <div className="text-primary-light">{property.value}</div>
                    </>
                  ) : (
                    <>
                      <div
                        className={
                          property.strike ? "line-through flex" : "flex"
                        }
                        style={{ fontSize: "13px" }}
                      >
                        {property.title}
                        {property?.tooltipText && (
                          <Tooltip text={property.tooltipText} />
                        )}
                      </div>
                      <div className="text-primary-light">{property.value}</div>
                    </>
                  )}
                </div>
              ))}
            </div>

            <div className="bg-secondary p-4 rounded-xl mt-2">
              {properties4.map((property, index) => (
                <div
                  key={index}
                  className="flex justify-between"
                  style={{ fontSize: "13px" }}
                >
                  {property.highlighted ? (
                    <>
                      <div className="text-primary-light font-bold mt-1">
                        {property.title}
                      </div>
                      <div className="text-primary-light">{property.value}</div>
                    </>
                  ) : (
                    <>
                      <div
                        className={property.strike ? "line-through" : ""}
                        style={{ fontSize: "13px" }}
                      >
                        {property.title}
                      </div>
                      <div className="text-primary-light">{property.value}</div>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="bg-secondary p-4 rounded-xl mt-2">
              <div
                className="flex justify-between"
                style={{ fontSize: "13px" }}
              >
                <div className="text-primary-light font-bold mt-1">
                  Quantity
                </div>
                <div className="text-primary-light text-right">
                  <input
                    type="number"
                    value={quantity}
                    className={`text-center text-white border border-primary-light rounded-xl bg-primary-dark p-1 mb-2 w-1/2`}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                </div>
              </div>
              {quantity && quantity > 1 && (
                <>
                  <div
                    className="flex justify-between"
                    style={{ fontSize: "13px" }}
                  >
                    <div className="text-primary-light font-bold mt-1">
                      Cost
                    </div>
                    <div className="text-primary-light">
                      {formatValue((prices.cost_price * quantity).toFixed(2))}
                    </div>
                  </div>
                  <div
                    className="flex justify-between"
                    style={{ fontSize: "13px" }}
                  >
                    <div className="text-primary-light font-bold mt-1">
                      Sale
                    </div>
                    <div className="text-primary-light">
                      {formatValue((prices.sale_price * quantity).toFixed(2))}
                    </div>
                  </div>
                  <div
                    className="flex justify-between"
                    style={{ fontSize: "13px" }}
                  >
                    <div className="text-primary-light font-bold mt-1">
                      Total Profit
                    </div>
                    <div className="text-primary-light">
                      {formatValue((profit * quantity).toFixed(2))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfitCalculator;
