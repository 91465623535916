import React, { useEffect, useState } from "react";
import GoogleSheetsAPI from "./GoogleSheetsAPI";

const GoogleSheets = ({ data, salesData, spreadsheetId, setSpreadsheetId }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [reqData, setData] = useState(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const reqData = {
      name: data?.basicInfo?.productName,
      brand: data?.basicInfo?.brand,
      category: data?.basicInfo?.category,
      manufacturer: data?.basicInfo?.manufacturer,
      description: data?.description,
      asin: data?.asin,
      EAN: data?.eanList ? data?.eanList[0] : "",
      BSR: data?.overview?.BSR?.BSR,
      EstimatedSales: salesData?.sales_estimation?.monthly_sales || "--",
      SalePrice: data?.overview?.prices?.sale_price,
      CostPrice: data?.overview?.prices?.cost_price,
      Profit: "$" + Number(data?.overview?.profit) || "--",
      ROI: data?.overview?.roi ?? "--",
      TotalFees:
        "$" + Number(data?.profitCalculator?.total_fees?.total) || "--",
    };

    setData(reqData);
  }, [data, salesData]);

  // ///////////////
  const GOOGLE_PRIVATE_KEY =
    "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC6xLQqJJUioS6+\nowV6Leq43LMxJjQKz/ZilmjT/GyW/fAu8JzqwRG5D4uhxMNxGFLFZop0ixK9RQPb\nxP4LJfTe4IiAFhPORtZFEmAZfaSxaGLLe6GJogDEEFPlVgOGy6yJ/NjYf4TO99ce\nCnYtG7h4ool5KFscgJfdqqRIDtgUdK0apSFl0M7uyrHKUUycU25P6ZznexqicDPD\ndUnDg1VJulCOmadlpGrjrLtda8H1FnJ8aJZFaoiGuW/GMaiUStZrKQzWdNXrdRJC\nE6e26ki88UctmvqQb85oEAHquHI9J3hCj6bmer6+Jo20v5hXSxX4H3XqsRZIXzh4\nseX19Yi3AgMBAAECggEAFiJv07rILfV6S7+YS5WbhtMpLX8AlfN1mNOIku8Mk4x+\n5aFAh5XgGtWbz804+HNQg/KWIB/HVcUTIv2iugGCltN3ee6j5EOC62a1GvBID3p2\nkvUQ/PXFL3db6gcaneQPi776mBXs0HaQ+Q92V/w8DZf9wO0mdydKWEv78kpIE4xY\nVRARPzHy5YrW8eBLFrsmYXQII56Lnk3nx3YcQ15l2ykcu3Hz4XtZT635higpK2Fr\nQxjNMY92CnHWeXX3qiS0TiWuCzBy8g9wtIXE7zdgpo+s2FL9yMZu7Y5H/kD/T6fj\ncu+z4hLzqeRATDOjayIyFu6KsJakBAXboM3MH3yKEQKBgQDwIh78sDtkIxN4aNMD\nJizcIc6NWZJdWnWGyO4v6NpIT4v7Bw1Ykx7jS2iwEHVnSzyvFZZpkatQy9XDpFDd\nuzrY6bZKwUoBDeISNxGpibwcnfr8xBJs5GLT2/jrMYvC4CLcfzn5eL0U76qxAECb\nZu79x4cjYpPcwyDxARODQpNjyQKBgQDHG+jqCCzEKr97JTOiQT34ChmO23D87oqk\nXS43kXerVNrZnVhRvdCvaNO1u3RZbRYGn3ohBbrQb8cQ2R7zYo8q/sulFQ1cYU1t\n7sdGsFnIYtlOM+FHLazPsxuegC/lfrq94eIxE/8Qg9UUGIgY9l8FREBU7vim9WkB\nvDHAftLIfwKBgQDaCuGAKLWE86SWTPhtf5h0Oe5HwvF3+s+El6ZPsmuiBTjhs0GX\nD9+YG7zvQcKZDGQJxtxQ94hLUQrFHsqey4dftrdjoacE1gpU59QN0ZcRlRF1usPQ\nRIDIRpD7Xn29ca+174BA2jU2cXjYR5ZihVC09xvqMXR5nGSo8YU6RpV2oQKBgBRy\nT0bJNB+o7CTvOxAsdvqjvuAmtMGklWQvlXFrZMiZXCgAunMadPWuZB9Dcr1EMIDZ\nm6cMeZSyBdU7FqyTsHQP2kXHlamo7FSfsqMVtQKY9AvN1BKFJDvn/qwWKdT9YJvj\nOGEl1omRoC4CHufBVRyrV5fhOnOau//LnahQ3cBtAoGABKIqhRTLhLXIDtcerMkY\nnvoYo4sbhzll/BQkP+SB0ClnxuYssPjUSVr2l/3EzjGpgu6xuqlVn+DLdBgnAnZE\neCosC0RAE4vcpmF1tzosevElgAIH4Lz8gRRh+bJobQqhNzsvdRf/v3Vkc8DF1Wkc\nHW5yGkHmSX0vYMryIVqTDKQ=\n-----END PRIVATE KEY-----\n";

  // const serviceAccountAuth = new JWT({
  //     // env var values here are copied from service account credentials generated by google
  //     // see "Authentication" section in docs for more info
  //     email: 'sushil@inductive-choir-428212-r0.iam.gserviceaccount.com',
  //     key: GOOGLE_PRIVATE_KEY,
  //     scopes: ['https://www.googleapis.com/auth/spreadsheets',
  //         'https://www.googleapis.com/auth/drive.file',]
  // });

  // const doc = new GoogleSpreadsheet('<the sheet ID from the url>', serviceAccountAuth);

  // const createSheet = async () => {
  //     const newDoc = await GoogleSpreadsheet.createNewSpreadsheetDocument(serviceAccountAuth, { title: 'new sheet 3' });

  //     // const newSheet = await doc.addSheet({ title: 'another sheet' });
  //     console.log('newDoc >>>> ', newDoc);
  // }
  // //////////////

  // Utility function to convert JSON to CSV with proper escaping
  const convertToCSV = (jsonData) => {
    const array = [Object.keys(jsonData[0])].concat(jsonData);

    return array
      .map((row) => {
        return Object.values(row)
          .map((value) => {
            if (typeof value === "string") {
              // Escape double quotes and wrap the value in double quotes
              value = value.replace(/"/g, '""');
              return `"${value}"`;
            }
            return value;
          })
          .join(",");
      })
      .join("\n");
  };

  // Function to download CSV
  const downloadCSV = () => {
    const reqData = {
      name: data?.basicInfo?.productName,
      brand: data?.basicInfo?.brand,
      category: data?.basicInfo?.category,
      manufacturer: data?.basicInfo?.manufacturer,
      description: data?.description,
      asin: data?.asin,
      EAN: data?.eanList ? data?.eanList[0] : "",
      BSR: data?.overview?.BSR?.BSR,
      EstimatedSales: salesData?.sales_estimation?.monthly_sales || "--",
      SalePrice: data?.overview?.prices?.sale_price,
      CostPrice: data?.overview?.prices?.cost_price,
      Profit: "$" + Number(data?.overview?.profit) || "--",
      ROI: data?.overview?.roi ?? "--",
      TotalFees:
        "$" + Number(data?.profitCalculator?.total_fees?.total) || "--",
    };

    // Convert reqData to an array of objects if it is not already
    const jsonData = [reqData];

    const csv = convertToCSV(jsonData);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");

    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div
      className="flex flex-col w-full border-primary-light bg-primary p-4 mt-4"
      style={{
        border: "0.5px solid #76F99B",
        borderRadius: "24px",
      }}
    >
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div
            className="mr-1 text-primary-light font-bold"
            style={{
              fontSize: "16px",
            }}
          >
            Google Sheets
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <>
            {/* <button className="bg-secondary text-primary-light py-2 px-4 rounded-md mt-2 hover:bg-primary-light hover:text-secondary transition duration-300"
                            onClick={downloadCSV}
                        >
                            Download CSV
                        </button> */}
            <GoogleSheetsAPI
              spreadsheetId={spreadsheetId}
              setSpreadsheetId={setSpreadsheetId}
              data={reqData}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GoogleSheets;
