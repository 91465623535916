import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { addLookup, fetchLookups } from "../../redux/actions/productActions";
import { toast } from "sonner";
import { MdSaveAs } from "react-icons/md";

const Lookup = ({ asin }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [lookups, setLookups] = useState([]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const getLookups = async () => {
    const response = await fetchLookups(asin);
    setLookups(response);
  };

  useEffect(() => {
    getLookups();
  }, [asin]);

  const lookUpSearch = (event) => {
    event.preventDefault();
    const source = searchTerm;
    const addLookupFunction = async () => {
      const response = await addLookup({ asin, source });
      if (response?.success) {
        toast.success("Lookup added successfully");
        getLookups();
      }
    };

    addLookupFunction();
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options);
  }

  return (
    <div className="flex flex-col w-full bg-primary p-4 mt-4 border border-primary-light rounded-2xl">
      <div className="">
        <div
          className="flex cursor-pointer items-center mb-2"
          onClick={toggleAccordion}
        >
          <div className="mr-1 text-primary-light font-bold text-lg">
            Lookup Details
          </div>
          <div>{/* {isOpen ? <FaAngleUp /> : <FaAngleDown />} */}</div>
        </div>
        {isOpen && (
          <div className="rounded-lg">
            <form onSubmit={lookUpSearch}>
              <div className="flex items-center mb-2">
                <div className="w-full">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Source"
                    className="text-primary-light bg-secondary outline-none focus:ring-0 focus:border-primary-light placeholder-primary-light"
                    style={{
                      border: "0.5px solid #76F99B",
                      borderRadius: "12px",
                    }}
                  />
                </div>
                <div className="ml-2">
                  <button
                    type="button"
                    onClick={lookUpSearch}
                    className="bg-primary-light p-1 rounded-lg"
                    style={{
                      border: "0.5px solid #76F99B",
                      borderRadius: "12px",
                    }}
                  >
                    <MdSaveAs className="text-primary cursor-pointer w-6 h-6" />
                  </button>
                </div>
              </div>
            </form>
            {/* Additional content can go here */}
            <div className="">
              {lookups?.length > 0 && (
                <table
                  className="w-full mt-2"
                  style={{ fontSize: "13px", borderCollapse: "collapse" }}
                >
                  <thead className="sticky top-0 bg-primary">
                    <tr className="" style={{ fontWeight: "bold" }}>
                      <div className="flex">
                        <div className="p-1 flex-2"></div>
                        <div className="p-1 flex-1">History</div>
                        <div className="p-1 flex-1">Source</div>
                        <div className="p-1 flex-1">Cost</div>
                        <div className="p-1 flex-1">Sale</div>
                      </div>
                    </tr>
                  </thead>
                  <tbody>
                    {lookups?.map((property, index) => (
                      <tr key={index}>
                        <td className="p-0">
                          <div
                            className="text-center text-white bg-secondary p-4 rounded-lg"
                            style={{
                              marginBottom: "2px",
                              marginTop: index === 0 ? "0" : "4px",
                              padding: "6px 2px 6px 6px",
                            }}
                          >
                            <div className="flex items-center justify-around">
                              {/* <div className="p-1 pl-0 pr-2 flex-2">{index + 1}</div> */}
                              <div className="p-1 flex-1">
                                {formatDate(property?.createdAt) || "-"}
                              </div>
                              <div className="p-1 flex-1 capitalize">
                                {property?.source || "Not Specified"}
                              </div>
                              <div className="p-1 flex-1">
                                $
                                {
                                  property?.data?.profitCalculator?.prices
                                    ?.cost_price
                                }
                              </div>
                              <div className="p-1 flex-1">
                                $
                                {
                                  property?.data?.profitCalculator?.prices
                                    ?.sale_price
                                }
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Lookup;
