export const calculateProfitMargin = (salePrice, costPrice, amazonFees) => {
  let profit = salePrice - (costPrice + amazonFees);
  profit = Math.round(profit * 100) / 100;
  const profitMargin = ((profit / salePrice) * 100).toFixed(2);
  return [
    profit && profit !== "NaN" ? profit : "--",
    profitMargin && profitMargin !== "NaN" ? profitMargin + "%" : "--",
  ];
};

export const miscFeeCalc = (costPrice, miscFeePercentage) => {
  return (costPrice * (miscFeePercentage / 100)).toFixed(2);
};

export const calculateBreakevenSalePrice = (
  costPrice,
  salePrice,
  amazonFees,
  referralFeePercent
) => {
  let fixed_price = amazonFees - salePrice * (referralFeePercent / 100);
  return (
    (Number(costPrice) + fixed_price) /
    (1 - referralFeePercent / 100)
  ).toFixed(2);
};

export const calculateEstimatedAmazonPayout = (salePrice, amazonFees) => {
  return (salePrice - amazonFees).toFixed(2);
};

// export const calculateROI = (salePrice, costPrice, amazonFees) => {
//     const profit = salePrice - (costPrice + amazonFees);
//     return ((profit / costPrice) * 100).toFixed(2) + "%";
// };

export const calculateROI = (sale_price, cost_price, amazonFees) => {
  const final_sale_price = sale_price - amazonFees;
  return Number(
    (((final_sale_price - cost_price) / cost_price) * 100).toFixed(2)
  );
};
const getPercentage = (s) => {
  return parseFloat(s / 100);
};

const getMiscPercentFees = (cost_price, settings) => {
  return cost_price * getPercentage(settings?.additionalCosts?.miscfeepercent);
};

const getMaxcost1 = (sale_price, totalfees, cost_price, settings) => {
  return (
    sale_price -
    totalfees -
    getMiscPercentFees(cost_price, settings) -
    parseFloat(settings?.buyingCriteria?.minProfit)
  );
};

const getMaxcost2 = (sale_price, settings, totalfees, cost_price) => {
  const roi = getPercentage(settings?.buyingCriteria?.minroi);
  const amazonFees = parseFloat(totalfees);
  return sale_price !== -1 && sale_price !== -2
    ? (
        (sale_price - (amazonFees - getMiscPercentFees(cost_price, settings))) /
        (1 + roi)
      ).toFixed(2)
    : 100000000;
};

export const calculateMaximumCost = (
  salePrice,
  costPrice,
  amazonFees,
  desiredProfit,
  profit,
  settings
) => {
  // console.log(profit, desiredProfit, "profit - desiredProfit");
  const maxcost1 = getMaxcost1(salePrice, amazonFees, costPrice, settings);
  const maxcost2 = getMaxcost2(salePrice, settings, amazonFees, costPrice);
  return (
    Math.min(maxcost1, maxcost2) -
    getMiscPercentFees(Math.min(maxcost1, maxcost2), settings)
  ).toFixed(2);
};
